import './Board.css';
function Board(props) {
    const tilesHTMLcontent = (props.statePointerBoard || []).map((letterKey, index) =>
        <li className='letter' key={letterKey} onClick={() => removeLetter(index, letterKey, 
            props.callBackUpdateBoard, props.statePointerBoard, props.callBackUpdateUsedLetters, props.statePointerUsedLetters
        )}>
            {props.letters[letterKey]}
        </li>
    );
    
    const wordsHTMLcontent = (props.statePointerSelectedWords || []).map((word, index) => 
        <div className='wordContainer' key={index}>
            <li className='word' wordid={index}>
                {word.map(letterKey => props.letters[letterKey]).join('')}
            </li>
            <button onClick={()=>removeWord(word, props.statePointerSelectedWords, props.updateSelectedWords, props.statePointerUsedLetters, props.callBackUpdateUsedLetters, props.updateCurrentScore, props.statePointerCurrentScore, props.validWord)}>
                X
            </button>
        </div>
    )

    const vaildWord = `${props.validWord === -1 ? 'Invalid' : 'Valid' } ${props.statePointerBoard.length === 0 ? 'hidden' : ''}`

    return (
        <div className="board">
            <ul className="words">
                {wordsHTMLcontent}
            </ul>
            <div className="currentWord">
                <ul className="tiles">
                    {tilesHTMLcontent}
                </ul>
                <button className={tilesHTMLcontent.length > 0 ? '' : 'hidden'} onClick={()=>submitWord(props.statePointerBoard, props.statePointerSelectedWords, props.updateSelectedWords, props.callBackUpdateBoard, props.updateCurrentScore, props.statePointerCurrentScore, props.validWord)}>
                    Submit word
                </button>
            </div>
            <div className={vaildWord + ' validStatus'}>{vaildWord} | {props.validWord === -1 ? '' : props.validWord}</div>
        </div>
    );
}

function submitWord(board, statePointerWords, callBackUpdateSelectedWords, callBackUpdateBoard, updateCurrentScore, currentScore, validWordScore) {   
    updateCurrentScore(currentScore+validWordScore)
    callBackUpdateSelectedWords(statePointerWords.concat([board]))
    callBackUpdateBoard([])
}

function removeLetter(boardIndex, letterKey, callBackUpdateBoard, statePointerBoard, callBackUpdateUsedLetters, statePointerUsedLetters) {
    callBackUpdateBoard(statePointerBoard.filter((letterKey, index) => {
        if (index === boardIndex) {
            return false
        } else {
            return true
        } 
    }))
    
    const copyOfObject = { ...statePointerUsedLetters }
    delete copyOfObject[letterKey]
    
    callBackUpdateUsedLetters({
        ...copyOfObject
    })
}

function removeWord(word, statePointerWords, callBackUpdateSelectedWords, statePointerUsedLetters, callBackUpdateUsedLetters, updateCurrentScore, currentScore, validWordScore) {
    const copyOfObject = { ...statePointerUsedLetters }
    updateCurrentScore(currentScore-validWordScore)
    
    word.forEach(letterKey => {
        delete copyOfObject[letterKey]
    });
    
    callBackUpdateUsedLetters({
        ...copyOfObject
    })
    callBackUpdateSelectedWords(statePointerWords.filter(stateWord => stateWord !== word))
}

export default Board;
