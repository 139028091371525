import './Tiles.css';
import { requestValidWord } from './Requests';

function Tiles(props) {
  let letters = props.letters
  let lettersHTML = []
  
  Object.keys(letters).forEach(letterKey => {
    const isDisabledState = props.statePointerUsedLetters[letterKey]

    const classes = `letter ${isDisabledState ? 'disabled' : null}`
    lettersHTML.push(
      <li className={classes} key={letterKey} onClick={(element) => {
        if (!isDisabledState) {
          selectLetter(letterKey, props.callBackUpdateUsedLetters, props.statePointerUsedLetters, 
            props.callBackUpdateBoard, props.statePointerBoard, props.callBackUpdateValidWord, letters
          )
        }
      }}>
        {letters[letterKey]}
      </li>
    )
  })

  return (
    <div className='hand'>
      <ul className="tiles">
        {lettersHTML}
      </ul>
    </div>
  );
}

function selectLetter(letterKey, callBackUpdateUsedLetters, statePointerUsedLetters, callBackUpdateBoard, statePointerBoard, callBackUpdateValidWord, letters) {
  callBackUpdateValidWord(null)
  callBackUpdateBoard(statePointerBoard.concat(letterKey))
  callBackUpdateUsedLetters({
    ...statePointerUsedLetters,
    ...{[letterKey]:true}})
    
  const word = statePointerBoard.map(_letterKey => letters[_letterKey]).join('')+letters[letterKey]
  requestValidWord(word)
    .then(response => response.json())
    .then(data => {
        callBackUpdateValidWord(data)
    });

}

export default Tiles;
