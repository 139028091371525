import './App.css';
import Tiles from './Tiles';
import React, { useState } from 'react';
import Board from './Board';
import { requestBestWord } from './Requests';


function App() {
  const [board, updateBoard] = useState([]);
  const [letters, updateLetters] = useState({});
  const [usedLetters, updateUsedLetters] = useState({});
  const [validWord, updateValidWord] = useState(-1);
  const [bestWord, updateBestWord] = useState([]);
  const [selectedWords, updateSelectedWords] = useState([]);
  const [currentScore, updateCurrentScore] = useState(0);
  const [loading, updateLoading] = useState(false);

  const postGameClasses = `postGame ${bestWord[0] ? '' : 'hidden'}`
  const postLoadingClasses = `postGame ${loading ? '' : 'hidden'}`

  return (
    <div className="App">
      <Board letters={letters} 
        callBackUpdateBoard={updateBoard} statePointerBoard={board} 
        callBackUpdateUsedLetters={updateUsedLetters} statePointerUsedLetters={usedLetters}
        validWord={validWord}
        updateSelectedWords={updateSelectedWords} statePointerSelectedWords={selectedWords} 
        updateCurrentScore={updateCurrentScore} statePointerCurrentScore={currentScore} 
      />
      <Tiles letters={letters} 
        callBackUpdateBoard={updateBoard} statePointerBoard={board} 
        callBackUpdateUsedLetters={updateUsedLetters} statePointerUsedLetters={usedLetters}
        callBackUpdateValidWord={updateValidWord}
      />
      <div className="background"></div>
      <button onClick={() => {
        updateLetters(generateHand(7))
        updateBestWord([])
        updateUsedLetters({})
        updateValidWord(-1)
        updateSelectedWords([])
        updateBoard([])
        updateCurrentScore(0)
      }}>
        Generate New Hand
      </button>
      <button className = {Object.keys(letters).length > 0 ? '' : 'hidden'} onClick={() => {
        updateLoading(true)
        requestBestWord(Object.keys(letters).map(letterKey => letters[letterKey]).join(''))
        .then(response => response.json())
        .then(data => {
          updateBestWord([Object.keys(data)[0], data[Object.keys(data)[0]]])
          updateLoading(false)
        });
      }}>
        Finish Game
      </button>
      <div className={postGameClasses}>
        <p>Your Score: {currentScore}</p>
        <p>Best possibility: {bestWord[0]} | {bestWord[1]}</p>
      </div>
      <div className={postLoadingClasses}>
        <p>Loading...</p>
      </div>
    </div>
  );
}

function generateHand(handSize) {
  const letters = 'abcdefghijklmnopqrstuvwxyz'
  let hand = {}

  for (let i = 0; i < handSize; i++) {
    hand[i] = letters[Math.floor(Math.random() * letters.length)]
  }

  return hand
}

export default App;
